<template>
    <v-card>
        <v-list-item>
            <v-list-item-avatar color="grey">
                <v-icon dark>mdi-clipboard-list</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                {{ pool.name }}
            </v-list-item-content>
        </v-list-item>
        <v-card-text>
            <p>
                Version: {{ pool.version }}
                <br />
                Uploaded Versions: {{ pool.versions.length }} <br />
                <br />
                Description: <br />
                {{ pool.description }}<br /><br />
            </p>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        text
                        small
                        v-on="on"
                        @click="$emit('open-version-dialog')"
                        ><v-icon>mdi-binoculars</v-icon> View Version
                        History</v-btn
                    >
                </template>
                <span>View Version History</span>
            </v-tooltip>
            <v-divider class="my-5" />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'VersionCard',
    components: {},
    props: {
        pool: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        build_type: '',
    }),
    computed: {
        docCount: function() {
            return 5
        },
    },
    methods: {
        viewPoolTargets(pool) {
            this.$router.push({ path: `/manuals/${pool.id}` })
        },
    },
}
</script>
