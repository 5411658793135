<template>
    <v-container fluid>
        <ApolloQuery
            :query="require('@/graphql/output-pool/allOutputPools.gql')"
            :variables="{ client_id: client_id, garbage: rando }"
            deep
        >
            <template v-slot="{ result: { loading, error, data }, query }">
                <v-progress-linear indeterminate v-if="loading" />
                <v-data-iterator
                    v-if="data && !loading"
                    :items="data.allOutputPools"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                            >
                                <version-card
                                    :pool.sync="item"
                                    @open-version-dialog="
                                        $emit('open-version-dialog', item)
                                    "
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
    </v-container>
</template>

<script>
import VersionCard from '@/views/user-dashboard/versions/VersionCard'
export default {
    name: 'VersionGrid',
    components: {
        'version-card': VersionCard,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        reload: {
            type: Number,
            default: 0,
        },
        client_id: {
            required: true,
        },
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12],
        itemsPerPage: 8,
        selectedPool: {},
        rando: 0,
    }),
    watch: {
        reload: function(val) {
            this.rando = val
        },
    },
    methods: {},
}
</script>
